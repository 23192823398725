.events {
    padding: 57px 0 62px;
    background: #FAFAFA;

    &__description {
        padding: 0 24px;
        margin-top: 32px;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 31px;
    }
}

.meeting {

    &__heading {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        padding: 0 24px;
    }

    &__list {
        margin-top: 23px;
        margin-bottom: 25px;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        line-height: 31px;
    }

    &__item {
        margin-bottom: 6px;
    }

    &__date:before {
        content: "";
        background: url("../assets/icons/date.svg");
        width: 16px;
        height: 18px;
        display: inline-block;
        position: relative;
        margin-right: 9px;
        top: 3px;
    }

    &__place:before {
        content: "";
        background: url("../assets/icons/place.svg");
        width: 15px;
        height: 18px;
        display: inline-block;
        position: relative;
        margin-right: 9px;
        top: 3px;
    }
}

.event_asw:before {
    content: "";
    display: block;
    background: url("../assets/icons/event_asw.png") no-repeat;
    background-size: contain;
    width: 122px;
    height: 44px;
    margin: 54px auto 32px;
}

.event_mac:before {
    content: "";
    display: block;
    background: url("../assets/icons/event_ags.png") no-repeat 50% 0;
    width: 222px;
    height: 56px;
    margin: 54px auto 32px;
}

.event_awe:before {
    content: "";
    display: block;
    background: url("../assets/icons/event_was.png") no-repeat;
    width: 123px;
    height: 40px;
    margin: 54px auto 32px;
}

.event_awg:before {
    content: "";
    display: block;
    background: url("../assets/icons/event_asw.svg") no-repeat;
    width: 84px;
    height: 51px;
    margin: 54px auto 32px;
}

.event_sigma:before {
    content: "";
    display: block;
    background: url("../assets/icons/event_sigma.png") no-repeat;
    background-size: cover;
    width: 222px;
    height: 46px;
    margin: 54px auto 32px;
}

@media only screen and (min-width: 768px) {
    .events {
        padding-top: 91px;
        padding-bottom: 90px;
        background: none;

        &__description {
            padding: 0 24px;
            margin-top: 35px;
            margin-bottom: 60px;
            font-size: 20px;
            line-height: 33px;
        }
    }

    .meeting {

        &__heading {
            font-size: 22px;
            line-height: 26px;
        }

        &__list {
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 18px 57px 9px;
        }
    }

    .event_asw:before {
        width: 200px;
        height: 70px;
        margin: 78px auto 40px;
    }

    .event_mac:before {
        margin: 78px auto 40px;
        width: 268px;
        height: 64px;
        background-size: contain;
    }

    .event_awe:before {
        margin: 78px auto 40px;
        width: 112px;
        height: 26px;
        background-size: contain;
    }

    .event_awg:before {
        margin: 80px auto 40px;
        width: 144px;
        height: 88px;
        background-size: contain;
    }

    .event_sigma:before {
        margin: 78px auto 40px;
        width: 268px;
        height: 56px;
        background-size: contain;
    }
}

@media only screen and (min-width: 1280px) {
    .events {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__heading {
            font-size: 36px;
            line-height: 56px;
            margin-bottom: 0;
        }

        &__description {
            margin-top: 16px;
            font-size: 18px;
            line-height: 34px;
            margin-bottom: 80px;
        }
    }

    .meeting {
        width: 1110px;
        height: 132px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: center;
        transition: 0.3s;

        &:hover {
            box-shadow: 2px 2px 24px rgba(11, 0, 77, 0.15);
        }

        &::before {
            display: none;
        }

        &__heading {
            width: 470px;
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 26px;
            font-weight: normal;
        }

        &__list {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 10px 0 0;
            width: 300px;
            margin-left: 10px;
        }

        &__date:before {
            width: 11px;
            height: 12px;
            background-size: contain;
            margin-right: 11px;
            top: 0;
        }

        &__place:before {
            width: 11px;
            height: 13px;
            background-size: contain;
            margin-right: 11px;
            top: 0;
        }
    }

    .event_asw:before {
        margin: 0 42px 0 18px;
        width: 122px;
        height: 44px;
    }

    .event_mac:before {
        margin: 0 42px 0 18px;
        width: 112px;
        height: 56px;
    }

    .event_awe:before {
        margin: 0 42px 0 18px;
        width: 114px;
        height: 35px;
    }

    .event_awg:before {
        margin: 0 90px 0 18px;
        width: 72px;
        height: 44px;
    }

    .event_sigma:before {
        margin: 0 40px 0 18px;
        width: 122px;
        height: 26px;
    }
}